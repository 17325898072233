import { Component, HostListener } from '@angular/core';
import { FeaturesFlagService } from '@shared/services/features-flag.service';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '@shared/services/profile.service';
import { Permission } from '@users/domain/services/user.enums';
import { UserService } from '@users/domain/services/user.service';
import { Meta } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private meta: Meta, private featuresFlagsService: FeaturesFlagService, private activatedRoute: ActivatedRoute,
              private profilService: ProfileService, private userService: UserService, private  translate: TranslateService) {
    this.featuresFlagsService.getAllFeaturesFlags();
    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params.asUser && profilService.isAuthenticated() && profilService.hasPermissions([Permission.MatelexAdminPermission])
        && profilService.userInfo?.email !== params.asUser) {
        await this.userService.adminAsUser(params.asUser);
      }

    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const value = { name: 'description', content: this.translate.instant('META.DESCRIPTION') };
      this.meta.addTag(value);
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth >= 905) {
      if (document.querySelector('.sidebar')) {
        document.querySelector('.sidebar').classList.remove('open');
      }
    }
  }
}
